'use client'

import { CdsLoader } from '@circutor/react-ui'
import { useRouter } from 'next/navigation'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@/contexts/auth.context'

import styles from './App.module.scss'

export default function RootPage() {
  const { t } = useTranslation()
  const router = useRouter()
  const authToken = useContext(AuthContext)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    if (authToken) {
      router.push('/home?assetId=all')
    } else {
      router.push('/login')
    }
  }, [authToken, router])

  return (
    <div className={styles.container}>
      {isClient && <CdsLoader text={t('common.loading') + '...'} />}
    </div>
  )
}
