'use client'

import { createContext, useEffect, useState } from 'react'

export const AuthContext = createContext({} as string | null)

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [authToken, setAuthToken] = useState<string | null>(null)
  let authTokenBase = null

  if (typeof window != 'undefined') {
    authTokenBase = localStorage.getItem('tb_token')
  }

  useEffect(() => {
    const token = localStorage.getItem('tb_token')
    setAuthToken(token)
  }, [])

  return (
    <AuthContext.Provider value={authTokenBase || authToken}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider }
